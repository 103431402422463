<template>
  <v-app>
    <v-app-bar app clipped-left elevation="0">
      <v-menu
          v-model="menu_aplicaciones"
          :nudge-width="200"
          offset-y
          content-class="mt-2"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-on="on" class="white--text"></v-app-bar-nav-icon>
        </template>
        <v-list dense>
          <v-subheader class="overline text--darken-1">
            <v-icon class="mr-2">mdi-cross</v-icon>Decesos Asistencia</v-subheader>
          <v-list-item link to="/partes" :disabled="!online">
            <v-list-item-content>
              <v-list-item-title>Partes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/dpartes">
            <v-list-item-content>
              <v-list-item-title>Partes desconectados</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
<!--      <v-img max-width="7%" contain src="SAtocha_logo_blanco.png"></v-img>-->
      <v-spacer></v-spacer>
      <portal-target name="botonera">
      </portal-target>
      <v-menu
          v-model="menu_usuario"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab icon v-on="on" class="ml-2 mr-1">
            <v-avatar color="blue" size="40">
              <span class="white--text text-h6">{{ iniciales }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                <v-list-item-subtitle>{{ usuario.codigo }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="mostrarDialogoCambiarPassword=true">
              <v-list-item-icon>
                <v-icon>mdi-lock-reset</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cambiar contraseña</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item @click="inicializarBDLocal">
              <v-list-item-icon>
                <v-icon>mdi-database-remove</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Inicializar BD local</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="arrancarDebug">
              <v-list-item-icon>
                <v-icon>mdi-bug</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Debug</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="desconectar">
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Desconectar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-tooltip bottom v-if="!online">
        <template v-slot:activator="{ on, attrs }">
          <v-icon large color="warning" v-bind="attrs" v-on="on">mdi-wifi-alert</v-icon>
        </template>
        <span>Sin conexión</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-snackbar :timeout="snackbar.timeout" v-model="snackbar.show" :color="snackbar.color" right top multi-line :vertical="snackbar.color=='error'" :dark="snackbar.dark" style="z-index: 99999999">
      {{ snackbar.text|truncate(210) }}
      <v-btn plain class="text-none pa-0" style="min-width: 0" v-if="snackbar.link!=null" link :to="snackbar.link" @click="snackbar.show=false">{{ snackbar.linkText }}</v-btn>
      <template v-slot:action>
        <v-btn color="#827e7c" icon @click="snackbar.show = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay" z-index="9999">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <confirm ref="confirm"></confirm>
    <v-dialog persistent v-model="mostrarDialogoCambiarPassword" max-width="1000px">
      <v-card>
        <v-card-title>Cambiar contraseña</v-card-title>
        <v-card-text>
          <ValidationObserver v-slot="{ invalid }" ref="validationObserverCambioPassword">
            <v-form>
              <v-row dense>
                <v-col>
                  <ValidationProvider name="password1" rules="required" v-slot="{ valid, errors }">
                    <v-text-field type="password" background-color="blue lighten-5" autocomplete="new-password" label="Contraseña" v-model="password1"  dense outlined counter maxlength="50" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <ValidationProvider name="password2" rules="required|password:@password1" v-slot="{ valid, errors }">
                    <v-text-field type="password" background-color="blue lighten-5" autocomplete="new-password" label="Repite contraseña" v-model="password2"  dense outlined counter maxlength="50" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="d-flex justify-center">
                  <v-btn color="#827E7C" dark elevation="0" @click="mostrarDialogoCambiarPassword=false;password1=null;password2=null"><v-icon>mdi-close</v-icon> Cancelar</v-btn>
                  <v-btn color="#17A34C" dark elevation="0" class="ml-3" @click="onClickAceptarCambiarPassword"><v-icon>mdi-check</v-icon> Aceptar</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import store from '@/store'
import entorno from '@/entorno'
import {mapState} from 'vuex';
import clone from 'just-clone';
import Confirm from "@/components/Confirm.vue";
import {ValidationObserver, ValidationProvider, extend, localize} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import es from 'vee-validate/dist/locale/es.json';
import eruda from 'eruda';

extend('required', required);
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Las contraseñas no coincide'
});

localize('es', es);

localize({
  es: {
    names: {
      password1: 'Contraseña',
      password2: 'Repite contraseña'
    }
  }
});

export default {
  name: "LayOut",
  components: {Confirm, ValidationObserver, ValidationProvider},
  data: () => ({
    menu_aplicaciones: false,
    menu_usuario: false,
    partesDB: null,
    mostrarDialogoCambiarPassword: false,
    password1: null,
    password2: null,
  }),
  watch: {
    //Si se pierde la conexión y estamos en una página que requiere conexión vamos a la home
    online(data) {
      const paginasNoDesconectadas = ['/partes'];
      if(!data){
        const enPaginaConectada = paginasNoDesconectadas.some(path => this.$router.currentRoute.path.startsWith(path));
        if(enPaginaConectada)
          this.$router.push('/');
      }
    }
  },
  filters: {
    truncate (value, length) {
      length = length || 15;
      if( !value || typeof value !== 'string' )
        return '';
      if( value.length <= length)
        return value
      return value.substring(0, length) + '...';
    },
  },
  computed: {
    iniciales() {
      let fullname = this.usuario.nombre.split(" ", 2);
      let primeraI = fullname[0] ? fullname[0].charAt(0) : '';
      let segundaI = fullname[1] ? fullname[1].charAt(0) : '';
      let initials = primeraI + segundaI;
      return initials;
    },
    ...mapState(['usuario', 'overlay', 'snackbar', 'online']),
  },
  methods: {
    desconectar() {
      store.commit('set', ['usuario', null]);
      this.$router.push('/login');
    },
    async inicializarBDLocal(){
      if (await this.$refs.confirm.open('Inicializa BD local', '¿Está seguro? Se borrarán todos los partes desconectados', { color: 'red' })) {
        this.partesDB.delete().then(async () => {
          this.partesDB=entorno.initDexie();
          if(this.$router.currentRoute.path.startsWith('/dpartes'))
            this.$router.push('/');
          store.commit('set', ['snackbar', {show: true,text: 'Base de datos local inicializada correctamente.',color: 'success',timeout: 5000}]);
        });
      }
    },
    arrancarDebug(){
      if(eruda._isInit)
        eruda.destroy();
      else
        eruda.init();
    },
    async leerVersiones(){
      await entorno.getAxios()
          .get(entorno.getApiUrl()+'/versiones', {"headers": entorno.getHeaders(store.getters.get('usuario').token)})
          .then(async response => {
            //LOV
            let versionLov=response.data.lov;
            //Revisamos si es diferente a la versión que hay en local
            let versionLocalLov=await this.partesDB.versiones.where({codigo: 'lov'}).first();
            versionLocalLov=versionLocalLov ? versionLocalLov.valor : 0;
            if(versionLov!==versionLocalLov)
              entorno.initDexieLov(this.partesDB, true);
          });
    },
    //TODO
    onClickAceptarCambiarPassword(){
      let entrada={
        "password1": this.password1,
        "password2": this.password2,
      };

      store.commit('set', ['overlay', true]);
      entorno.getAxios()
          .post(entorno.getApiUrl()+'/auth/change-password',{...entrada}, {"headers": entorno.getHeaders(this.usuario.token)})
          .then(response => {
            this.mostrarDialogoCambiarPassword=false;
            this.password1=null;
            this.password2=null;
            store.commit('set', ['snackbar', {show: true, text: 'Contraseña cambiada correctamente', color: 'success', timeout: 5000}]);
          })
          .catch(error => {
            console.log(error.response);
            store.commit('set', ['snackbar', {show: true, text: error.response.data.message!=null ? error.response.data.message : 'Se ha producido un error', color: 'error', timeout: -1}]);
          })
          .finally(() => {
            store.commit('set', ['overlay', false]);
          });

    }
  },
  mounted() {
    this.partesDB=entorno.initDexie();
    this.leerVersiones();
    window.addEventListener('online', ()=>{
      store.commit('set', ['online', true]);
    });
    window.addEventListener('offline', ()=>{
      store.commit('set', ['online', false]);
    });
  },
  beforeDestroy() {
    window.removeEventListener('online', null);
    window.removeEventListener('offline', null);
  }
}
</script>

<style>
#app {
  background: url('@/assets/img/degradado.svg');
  background-size: cover;
}

header.v-sheet.theme--light.elevation-0.v-toolbar.v-app-bar.v-app-bar--fixed {
  background-color: transparent;
  border-color: transparent;
}

header.v-sheet.theme--light.elevation-0.v-toolbar.v-app-bar.v-app-bar--fixed.v-app-bar--is-scrolled {
  background-color: #255F85;
  border-color: #255F85;
}
</style>